/**************************************************
 * Nombre:       BarraNoticias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid, useMediaQuery} from "@mui/material";
import logo from "../../../Recursos/logo.svg";
import {useNavigate} from "react-router-dom";
import {theme} from "../../../Tema";
import {Notification} from "iconsax-react";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {adicionarElemtoArray} from "../../../Servicios/BD/adicionarElemtoArray";


const BarraNoticias = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()


    const guardarToken = () => {

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{maxWidth: '1400px', px: 3}}
            spacing={2}
        >

            <Grid item lg={8} sm={6} xs={6}>
                <ButtonBase onClick={() => navigate('/Home')}>
                    <img src={logo} width={sCell ? 160 : 200} height={'auto'} alt={'logo'}
                         title={'international logistics MD'}/>
                </ButtonBase>
            </Grid>

            {!sCell &&

            <Grid item lg={2} sm={3} xs={6}>
                <Button
                    onClick={() => navigate('/Home')}
                    variant={'text'} sx={{color: '#000', px: 0, fontSize: 16}}>Informacion de la
                    empresa</Button>
            </Grid>
            }

            <Grid item container lg={2} sm={3} xs={6} sx={{justifyContent: "flex-end"}}>
                <Button
                    onClick={() => guardarToken()}
                    startIcon={<Notification
                        variant={'Bold'}/>}>{sCell ? 'Notificaciones' : 'Notificaciones'}</Button>
            </Grid>

        </Grid>
    )
}

export default BarraNoticias;