/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useContext, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {
    ArchiveBook,
    ArchiveBox,
    ClipboardTick,
    HambergerMenu,
    Messages2,
    TickSquare,
    UserAdd,
    UserTick,
    Warning2
} from "iconsax-react";
import {salirAunth} from "../Servicios/Auth/funSalir";
import logo from '../Recursos/logo.svg'
import logoLargo from '../Recursos/logoblanco.svg'
import fondo from '../Recursos/fondoperfil.svg'
import {Barra, DashboardBase} from "../Modulo_Dashboard/Componentes/C_Dashboard";
import {usuarioContext} from "../App";
import Boletines from "./Secciones/Boletines";
import Potenciales from "./Secciones/Potenciales";
import Pqrs_Seccion from "./Secciones/Pqrs_Seccion";
import Checking from "./Secciones/Checking";


const Dashboard = () => {
    const cData = useContext(usuarioContext);
    const [credenciales, setCredenciales] = useState([])


    useEffect(() => {
        if (cData && cData.usuario) {
            setCredenciales(cData.usuario.credenciales)
        }
    }, [cData])

    useEffect(() => {
        /* obtenerToken().then((dox) => {
             if (dox.res) {
                 suscribirTopic({token: dox.data, topic: 'torre'})
             }
         })*/

    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <DashboardBase componentes={componentes} componentesID={componentesID}>

                <DashboardBase.Barra>

                    <Grid item lg={4} sm={4} xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <Grid item lg={2} sm={2} xs={4}>
                                <Barra.OpenDrawer>
                                    <HambergerMenu color={"#fff"}/>
                                </Barra.OpenDrawer>
                            </Grid>

                            <Grid item lg={10} sm={8} xs={8}>
                                <Barra.Titulo/>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Grid item container lg={8} sm={8} xs={6} sx={{justifyContent: "flex-end"}}>
                        <img src={logoLargo} width={90}/>
                    </Grid>


                </DashboardBase.Barra>

                <DashboardBase.Drawer credenciales={credenciales}
                                      subMenus={subMenus}
                                      logo={logo} usuario={cData.usuario} fondo={fondo}
                                      funSalir={salirAunth}/>

                <DashboardBase.Main/>

            </DashboardBase>


        </Grid>
    )
}

export default Dashboard;


const subMenus = [
    {
        nombre: 'Contactos',
        items: ['Nuevos', 'Contactando', 'Seguimiento', 'Archivados']
    }
]


const componentes = [
    {
        seccion: <Boletines/>,
        credencial: "Boletines",
        icono: <ArchiveBook variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <ArchiveBook variant={"Bold"} color={"#fff"} size={"2.5rem"}/>
    },
    {
        seccion: '',
        credencial: "Contactos",
        icono: <UserAdd variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <UserAdd variant={"Bold"} color={"#fff"} size={"2.5rem"}/>
    },
    {
        seccion: <Potenciales estado={'nuevo'}/>,
        credencial: "Nuevos",
        icono: <UserAdd variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <UserAdd variant={"Bold"} color={"#fff"} size={"2.5rem"}/>
    },
    {
        seccion: <Potenciales estado={'contactando'}/>,
        credencial: "Contactando",
        icono: <Messages2 variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <Messages2 variant={"Bold"} color={"#fff"} size={"2.5rem"}/>
    },
    {
        seccion: <Potenciales estado={'seguimiento'}/>,
        credencial: "Seguimiento",
        icono: <UserTick variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <UserTick variant={"Bold"} color={"#fff"} size={"2.5rem"}/>
    },
    {
        seccion: <Potenciales estado={'archivado'}/>,
        credencial: "Archivados",
        icono: <ArchiveBox variant={"Bold"} color={"#fff"}/>,
        iconoGrande: <ArchiveBox variant={"Bold"} color={"#fff"} size={"2.5rem"}/>
    },
    {
        seccion: <Pqrs_Seccion estado={false}/>,
        credencial: "Pqrs",
        icono: <Warning2 variant={"Bold"} color={"#FF6623"}/>,
        iconoGrande: <Warning2 variant={"Bold"} color={"#FF6623"} size={"2.5rem"}/>
    },
    {
        seccion: <Pqrs_Seccion estado={true}/>,
        credencial: "Pqrs_Archivadas",
        icono: <TickSquare variant={"Bold"} color={"#FF6623"}/>,
        iconoGrande: <TickSquare variant={"Bold"} color={"#FF6623"} size={"2.5rem"}/>
    },
    {
        seccion: <Checking estado={true}/>,
        credencial: "Checking",
        icono: <ClipboardTick variant={"Bold"} color={'#fff'}/>,
        iconoGrande: <ClipboardTick variant={"Bold"} size={"2.5rem"} color={'#fff'}/>
    },


]


const componentesID = []