import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {enviarNotificacionMultiplesTokens} from "./enviarNotificacionMultiplesTokens";

export const enviarNotificacionAdmins = async ({mensaje, titulo}) => {

    let result = await obtenerDoc('tokens', 'tokens')

    if (result.res) {
        //console.log(result.data.arrTokens)
        let arrToken = result.data.arrTokens
        let res = await enviarNotificacionMultiplesTokens({arrTokens: arrToken, titulo: titulo, mensaje: mensaje})
    }


}