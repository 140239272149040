import {obtenerTokenBarrer} from "../Utilidades/obtenerTokenBarrer";
import {IDPROYECTO} from "../Constantes";
import axios from "axios";
import logo from '../../Recursos/logo.svg'

export const enviarNotificacionMultiplesTokens = async ({arrTokens, titulo, mensaje, img, funcion = null}) => {


    let barrer = await obtenerTokenBarrer()

    let authToken = barrer.data

    for (let i = 0; i < arrTokens.length; i++) {
       // console.log('es')
        await enviarUnaNotificacion({
            token: arrTokens[i],
            mensaje: mensaje,
            titulo: titulo,
            authToken: authToken
        })
    }


}

const enviarUnaNotificacion = ({token, titulo, mensaje, authToken}) => {

    const fcm = `https://fcm.googleapis.com/v1/projects/${IDPROYECTO}/messages:send`
    let data = JSON.stringify({
        "message": {
            "token": token,
            "notification": {
                "title": titulo,
                "body": mensaje,

            },
            "webpush": {
                "headers": {
                    "Urgency": "high"
                },
                "notification": {
                    "body": mensaje,
                    "requireInteraction": "true",
                    "badge": logo
                }
            }
        }
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: fcm,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken
        },
        data: data
    };

    return axios.request(config)
        .then((response) => {
           // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          //  console.log(error);
        });

}